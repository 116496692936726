import type { MetaFunction } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import { cleanPage, fetchPage, PageViewer, useReactBricksContext } from 'react-bricks/frontend'

import { SomethingWrong } from '~/components/error'
import Layout from '~/components/Layout'
import { DEFAULT_DESCRIPTION, DEFAULT_TITLE } from '~/constants'

export const loader = async ({ params }: { params: { slug: string } }) => {
  try {
    const page = await fetchPage(params.slug, process.env.REACT_BRICKS_API_KEY as string)
    return { page }
  } catch (e) {
    console.error(e)
    // TO-DO throw 404
    // throw new Error(`Cannot find the "${params.slug}" page.`)
  }
  return true
}

export const meta: MetaFunction = ({ data }) => {
  const title = data?.page?.meta?.title ?? DEFAULT_TITLE
  const description = data?.page?.meta?.description ?? DEFAULT_DESCRIPTION
  return {
    title,
    description
  }
}

const Page = () => {
  const { page } = useLoaderData()
  const { pageTypes, bricks } = useReactBricksContext()

  const pageOk = page ? cleanPage(page, pageTypes, bricks) : null

  return (
    <Layout>
      <PageViewer page={pageOk} />
    </Layout>
  )
}

export function ErrorBoundary({ error }: { error: Error }) {
  return <SomethingWrong error={error} />
}

export default Page
